import './Introduction.css'
import restaurant from './restaurant.jpg'

function Video() {
    return (
        <div className="intro-container">
            <img src={restaurant} alt="restaurant" id="intro-image" />
            
            <div className="intro-overlay">
                <div className="intro-overlay-content">
                <h1 className="title is-1 is-size-2-mobile has-text-white">La Table du Chef</h1>
                    <h2 className="subtitle is-3 is-size-4-mobile has-text-white">Restaurant Gastronomique</h2>
                    <h3><i className="fa-solid fa-map-pin"></i> 3 Rue de l'Échelle, 75001 Paris</h3>
                <a href="book.html" className="button is-main is-rounded is-large"><i className="fa-solid fa-calendar-check"></i><b>&nbsp; <span>Réserver en ligne</span></b></a>
                <a id="phone-button" href="tel:0621772546" className="button is-secondary is-rounded is-large"><i alt="Phone" aria-hidden="true" className="fa-solid fa-phone"></i> <b>&nbsp; <span>Nous joindre</span></b></a>
                <br />
                <a href="/packages.html" className="button is-main is-rounded is-large"><b><i className="fa-solid fa-utensils"></i> &nbsp;<span>Consulter le menu</span></b></a>
                </div>
            </div>

        </div>
    )
}

export default Video;

import './BusinessDetails.css';
import $ from 'jquery';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
import 'leaflet-extra-markers';
import { useEffect } from 'react';

function BusinessDetails() {
    useEffect(() => {
        window.onload = function () {

            const map = new L.Map('map',{
                center: [48.86387305845145, 2.3340015518825927], // starting position [lng, lat]
                zoom: 14, // starting zoom
                zoomControl: false, // we don't need zoom control,
                // cannot move on the map on phone
                dragging: false,
                tap: false,
                scrollWheelZoom: false,
                doubleClickZoom: false,
                boxZoom: false,
                
                
            });
            
            L.tileLayer('https://api.mapbox.com/styles/v1/gryzel/ckdbmd3c32avp1ipums203iyo/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3J5emVsIiwiYSI6ImNrZGJpdjljeTAweGIyc3FxZDB4MzVvZXEifQ.XiswFFm7f-tdBvHntEp3VA', {
            }).addTo(map);

            $('.leaflet-control-attribution').hide();

            var locMarker = L.ExtraMarkers.icon({
                icon: 'fa-solid fa-utensils',
                markerColor: '#004225',
                svg: 'true',
                shape: 'circle',
                prefix: 'fas'
            });
            var marker = L.marker([48.86387305845145, 2.3340015518825927], { icon: locMarker }).addTo(map);

            marker.bindPopup("<b>Nous sommes ici!</b>").openPopup();
            
        }

    }, [])
    return (
        <div className="business-details-container container">

            <br /><br />
            <div className='columns'>
                
                <div className='column'>
                    <h1 className="title is-main"><b><span>Nos horaires</span></b></h1>

                    <h2 className="subtitle is-black"><i className="fa-solid fa-clock"></i> N'hésitez pas à réserver à l'avance!</h2>


                    <table className="table">
                        <thead>
                            <tr>
                            <th>Jour</th>
                            <th>Midi</th>
                            <th>Soir</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>Lundi</td>
                            <td>12h-14h30</td>
                            <td>19h-22h</td>
                            </tr>
                            <tr>
                            <td>Mardi</td>
                            <td>12h-14h30</td>
                            <td>19h-22h</td>
                            </tr>
                            <tr>
                            <td>Mercredi</td>
                            <td>12h-14h30</td>
                            <td>19h-22h</td>
                            </tr>
                            <tr>
                            <td>Jeudi</td>
                            <td>12h-14h30</td>
                            <td>19h-22h</td>
                            </tr>
                            <tr>
                            <td>Vendredi</td>
                            <td>12h-14h30</td>
                            <td>19h-22h</td>
                            </tr>
                            <tr>
                            <td>Samedi</td>
                            <td>12h-14h30</td>
                            <td>19h-22h</td>
                            </tr>
                            <tr>
                            <td>Dimanche</td>
                            <td>12h-14h30</td>
                            <td>19h-22h</td>
                            </tr>
                        </tbody>
                        </table>
                    
                </div>
                
                <div className='column'>
                    <h1 className="title is-main"><b><span>Nous trouver</span></b></h1>
                    
                    <h2 className="subtitle is-black"><i className="fa-solid fa-location-dot"></i> 3 Rue de l'Échelle, 75001 Paris</h2>

                    <br />

                    <div id="map">
                        
                    </div>

                    <br /><br />

                </div>

        </div>
        </div>
    );
}

export default BusinessDetails;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Navbar from './components/navbar/Navbar';
import Introduction from './components/introduction/Introduction';
import BusinessDetails from './components/businessDetails/BusinessDetails';
import Gallery from './components/gallery/Gallery';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <div>
          <Routes>
              <Route
                  path="/"
                  element={
                      <div>
                        <Navbar />
                        <Introduction />
                        <BusinessDetails />
                      </div>
                  }
              />
              <Route
                  path="/gallery"
                  element={
                      <div>
                        <Navbar />
                        <br /><br /><br /><br />
                        <Gallery />
                      </div>
                  }
              />
          </Routes>
      </div>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import './Navbar.css'
import logo from '../../logo.png'
import { useEffect } from 'react';
import $ from 'jquery';

function Navbar() {
    useEffect(() => {
        const handleMobileMenuButtonClick = () => {
            if ($('#mobile-menu').is(':visible')) {
                $('#mobile-menu').hide();
                $('#mobile-menu-button').html('<i class="fa-solid fa-bars"></i>');
            } else {
                $('#mobile-menu').show();
                $('#mobile-menu-button').html('<i class="fa-solid fa-times"></i>');
            }
        };

        const handleWindowScroll = () => {
            $('#mobile-menu').hide();
            $('#mobile-menu-button').html('<i class="fa-solid fa-bars"></i>');
        };

        $('#mobile-menu-button').click(handleMobileMenuButtonClick);
        $(window).scroll(handleWindowScroll);

        return () => {
            $('#mobile-menu-button').off('click', handleMobileMenuButtonClick);
            $(window).off('scroll', handleWindowScroll);
        };
    }, [])
    return (
        <div className="navbar-container">
            <nav className="navbar is-main is-fixed-top" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/">
                        <img id="navbar-logo" alt="Logo" src={logo} height="50" />
                    </a>
                </div>
            
                <div className="navbar-menu">
            
                    <div className="navbar-end">
                        <div className="navbar-item">
                            <a href="/" className="is-main">
                                <b>Réservation</b>
                            </a>
                        </div>
                        <div className="navbar-item">
                            <a href="/" className="is-main">
                                <b>Menu</b>
                            </a>
                        </div>
                        <div className="navbar-item">
                            <a href="/gallery" className="is-main">
                                <b>Galerie</b>
                            </a>
                        </div>
                    </div>
                </div>
            
                <div className="is-hidden-desktop">
                    <span id="mobile-menu-button" className="navbar-item"><i className="fa-solid fa-bars"></i></span>
                </div>
            </nav>
            
            <div id="mobile-menu">
                <div className="mobile-menu-item"><a href="/"><b>Réservation</b></a></div>
                <div className="mobile-menu-item"><a href="/"><b>Menu</b></a></div>
                <div className="mobile-menu-item"><a href="/gallery"><b>Galerie</b></a></div>
            </div>

        </div>
    )
}

export default Navbar
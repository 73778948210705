import './Gallery.css'
import PhotoAlbum from "react-photo-album";

const photos = [
    { src: "https://images.unsplash.com/photo-1623765306406-b8bad9a7644c?q=80&w=2708&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", width: 1600, height: 881 },
    { src: "https://images.unsplash.com/photo-1567932847833-ecb7e227c2a3?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", width: 1321, height: 881 },
    { src: "https://images.unsplash.com/photo-1499856871958-5b9627545d1a?q=80&w=2620&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", width: 1245, height: 881 },
    { src: "https://images.unsplash.com/photo-1509402308-817902776267?q=80&w=2115&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", width: 621, height: 881 },
    { src: "https://images.unsplash.com/photo-1518057111178-44a106bad636?q=80&w=2136&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", width: 2136, height: 3200 },
    { src: "https://images.unsplash.com/photo-1600850056064-a8b380df8395?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", width: 587, height: 881 },
    { src: "https://images.unsplash.com/photo-1551024506-0bccd828d307?q=80&w=2564&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", width: 704, height: 881 },
    { src: "https://images.unsplash.com/photo-1550340499-a6c60fc8287c?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", width: 1321, height: 881 },
    { src: "https://images.unsplash.com/photo-1503453776591-b4548af666a2?q=80&w=2576&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", width: 588, height: 881 },
];

function openImage(event, photo) {
    window.location.href = photo.src;
}

function Gallery() {
    return (
        <div className="gallery-container container">
            <h1 className="title is-main"><b><span>Galerie</span></b></h1>
            <h2 className="subtitle is-black"><i className="fa-solid fa-camera"></i> Voici à quoi vous attendre!</h2>
            <PhotoAlbum layout="columns" photos={photos} onClick={({ event, photo }) => openImage(event, photo)} />
        </div>
    )
}

export default Gallery;